@font-face {
  font-family: pixelFont;
  src: url(/src/assets/font/pixel.ttf);
}

body {
  background-color: #c2e7e5b6;

  @media screen and (max-width: 500px) {
    overflow-x: hidden;
  }
}
